/* Basic styles for the contact bar at the bottom */
.contact-bar {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    z-index: 999;
    transition: width 0.5s ease;
    width: 20%;
  
 
    box-sizing: border-box; /* Include padding in width calculation */
}

.contact-bar.hidden {
    display: none;
}

/* Container for the contact form */
.contact-form-container {
    position: fixed;
    bottom: -100%; /* Hidden by default */
    right: 0;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    max-height: 1000px;
    overflow-y: auto;
    transition: bottom 0.5s ease;
    z-index: 1000;
    width: 80%;
    padding-top: 50px;
}



/* When the form is active (slid up) */
.contact-form-container.active {
    position: fixed;
    bottom: 0;
    right: 0;
 
    text-align: center;
    padding: 10px;
    cursor: pointer;
    z-index: 999;
    transition: width 0.5s ease;
    width: 30%;
 
    box-sizing: border-box; /* Include padding in width calculation */
}

.form-header {
    display: block; /* Set to block to ensure text paragraphs are stacked */
    background-color: #333; /* Dark background for better contrast */
    color: white; /* White text for better readability */
    padding: 20px; /* Increased padding for better space */
    font-size: 16px; /* Font size adjustment */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Add a shadow effect */
    position: relative; /* Ensure the close button is positioned correctly */
    margin-bottom: 40px;
}
.contact-bar p {
    text-align: center !important;
}

/* Adjust the paragraphs inside the form header */
.form-header p {
    margin: 0;
    margin-bottom: 0px; /* Add margin to separate paragraphs */
    text-align: center; /* Align text to the left */
}

/* Close arrow positioned at the top right */
.close-arrow {
    position: absolute;
    top: 10px; /* Align to the top */
    right: 10px; /* Align to the right */
   
    color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px; /* Size adjustment */
    z-index: 1000; /* Ensure it stays on top */
    transition: background-color 0.3s ease;
}

/* Add hover effect for close button */
.close-arrow:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Darker on hover */
}





@media (max-width: 768px) {
    .contact-form-container {
        width: 50%; /* Take most of the screen width but with some padding */
        left: 5%; /* Center it with 5% padding on both sides */
        padding: 10px; /* Adjust padding for smaller screens */
    }
}

/* For even smaller screens, like phones */
@media (max-width: 480px) {
    .contact-form-container {
        width: 100%; /* Ensure the form takes up the entire screen width */
        left: 0;
        right: 0;
        padding: 10px; /* Ensure padding on smaller devices */
    }

    .contact-bar {
       
        left:0;
        right: 0;
    }

   

 

    .contact-bar {
       
        height: 40px;
}
}