/* General styles for the menu bar */
.menu-bar {
  .menu-bar {
    width: 100vw;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for clarity */
  }

}

.menu-bar-wrapper {
  width: 100vw;

  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 99;
  height: 4vw;
}

.menu-content {
  
  margin: 0 auto;
  padding: 0 25%; /* Optional padding */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}


.menu-icon {
  background: none;
  border: none;
  color: #333;
  font-size: 2vw;
  cursor: pointer;

}
.menu-icon:focus {
  outline: none; /* Ensures no outline appears on focus */
}
button.menu-icon{
  margin-left: 0px;
}


.menu-title {
  font-family: 'DM Serif Display', serif; /* Match the closest serif font */
  font-size: 3rem; /* Adjust the font size as needed */
 
  letter-spacing: 0.02em; /* Slightly adjust letter spacing for a more refined look */
  text-transform: uppercase; /* Keep it uppercase for consistency */
  color: #000; /* Make the color pure black */
  margin: 0 auto; /* Center the title */
  padding-left: 10px; /* Optional, adjust as necessary to make it fit perfectly */
}
 
/* Style the menu items */
.menu-items {
  position: absolute;
  left: 25%;
  top: 3vw;
  background-color: white; /* Light background to match the contact form */
  color: #333; /* Darker text color for readability */
  width: 20vw;
  padding: 2vw;
  display: none;
  z-index: 100;
  box-shadow: 0 0.1vw 0.5vw rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}



/* Hover effect to show the menu */
.menu-content:hover .menu-items {
  display: block;
  outline: none;
}

.menu-items ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-items ul li {
  margin: 2vw 0;
}

.menu-items ul li a {
  color: #333; /* Matches text color across the page */
  text-decoration: none;
  font-size: 1.4vw;
  font-family: 'Avenir Book'; /* Align with font family */
}

.menu-items ul li a:hover {
  text-decoration: underline;
}

/* Additional style to match the overall page */
.menu-items ul li a:hover {
  background-color: #e6e6e6; /* Light background hover effect */
  padding: 0.25vw 0.5vw;
  border-radius: 0.25vw;
}

@media (max-width: 480px) {
  .menu-items {
    position: absolute;
    left: 5%;
    top: 4%;
    background-color: white; /* Light background to match the contact form */
    color: #333; /* Darker text color for readability */
    width: 40vw;
    padding: 2vw;
    display: none;
    z-index: 100;
    box-shadow: 0 0.1vw 0.5vw rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }

  .menu-items ul li a {
    color: #333; /* Matches text color across the page */
    text-decoration: none;
    font-size: 2vw;
    font-family: 'Avenir Book'; /* Align with font family */
  }
  .menu-content {
    padding: 0 5%;
    min-height: 20vw
  }
 
  .menu-bar{
    min-height: 20vw;
  }
  .menu-icon {
    font-size: 6vw;
  }
  img.menu-logo {
    max-width: 30vw;
    min-width: 20vw;
    max-height: 20vw;
    min-height: 6vw;
    padding: 0vw;
    margin-left: 25%;
  }
  
}