/* Contact Section Container */
.contact-section {
    display: flex;
    justify-content: space-between;
   


  
    background-color: #f2f0f0;
  
}

.contact-info {
    flex: 1;
 
    background-color: #fff;
   
    font-family: 'Avenir Book'
   
}

.contact-info p {
    margin-bottom: 20px;
}

/* Form Container */
/*.contact-form {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    font-family: 'Avenir Book';
    border: 1px solid #ddd;
}*/


.contact-form-container.active {
    bottom: 0; /* Move the form up to the bottom of the screen */
}

/* Input Styling */
.contact-form input[type="text"], 
.contact-form input[type="email"], 
.contact-form textarea {
    background-color: #f3f0f0;
    padding: 0.8vw;
    margin-bottom: 1.1vw;
    border: none;
    font-family: 'Avenir Book';
    font-size: 0.8vw;
    box-sizing: border-box;
    border-radius: 0px;
    width: 100%;
    color: black;
}

/* Styling for the text areas */
.contact-form textarea {
    min-height: 10vw;
    resize: none;
}

/* Checkbox Styling */
.contact-form .checkbox-row {
    display: flex;
    justify-content: flex-start;
    gap: 1vw;
    margin-bottom: 1vw;
    align-items: center;
}

/* Label and input alignment */
.contact-form label {
    display: inline-flex;
    align-items: center;
    font-size: 0.7vw;
    font-family: "Avenir Book";
    margin-bottom: 0;
}.contact-form {
    max-width: 50vw;
    margin: 0 auto;
    
}



/* Custom checkbox styles */
.contact-form input[type="checkbox"] {
    margin-right: 0.5vw;
    transform: scale(1.3);
    vertical-align: middle;
    
    max-width: 1.5vw;
    max-height: 1.5vw;
   
    background-color: #f3f0f0;
    cursor: pointer;
}

/* Custom checkbox appearance when checked */
.contact-form input[type="checkbox"]:checked {
    background-color: #333;
    border-color: #333;
}

/* Button Styling */
.contact-form button {
    padding: 0.4vw 0.8vw;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 0.8vw;
    text-transform: uppercase;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #555;
}

/* Row styling for specific fields (for aligning mail and address in the same row) */
.contact-form .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.contact-form .form-row input {
    width: 100%;
}