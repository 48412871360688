
@font-face {
    font-family: 'CEBT';
    src: url('../fonts/CenturyExpandedBT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'CEitBT';
    src: url('../fonts/CenturyExpandedItalicBT.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir Book';
    src: url('../fonts/avenir-book.ttf') format('truetype');
    
}

.signature-pic{
    max-height: 4vw;
    max-height: 4vw;
}
.fabian{
    font-size: 1.2vw;
    font-family: "Avenir Book";
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.fabian p{
   font-family: "Avenir Book";
}
.omdomen {
    display: none !important;
    color: #333;
    margin: 6vw;
    padding: 6vw;
    block-size: 6vw;
}

html {
   
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: 'CEitBT';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
p{
    text-align: left;
    padding: 0px;
    margin: 0px;
    font-family: "CEBT";
}
/* Ensure all headers, paragraphs, and other elements use the Century font */
h1, h2, h3, h4, h5, h6, p, div, button, a {
    font-family: 'CEitBT';
   
   
}

h2#what-we-offer, h2#our-fridge, h2#catering, h2#results, h2#contact-order{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 4vw;
    font-style: italic;
    margin-top: 4vw;
    font-size: 3vw;
    
    letter-spacing: -1%;
}

ul {
    margin-top: 0% !important;
    list-style-type:circle;
    padding-left: 0; /* Removes default left padding from the list */
    margin-left: 1.5vw; /* Ensures no extra margin on the left */
    font-family: "CEBT";

}

li {
    margin-top: 0% !important;
    margin-bottom: 0.5vw; /* Optional: Adds spacing between list items */
    text-align: left; /* Ensures text alignment is to the left */
    font-family: "CEBT";
    font-size: 0.8vw;

    hyphens: auto;
    
    list-style-type: disc;
}

::marker {
    margin-left: 3vw;
}

li.numbers {
    list-style-type: none; /* Remove default list style */
    counter-increment: custom-counter; /* Add custom counter */
    font-weight: bold; /* Make the numbers bold */
    font-size: 24px; /* Adjust the font size as needed */
    margin-bottom: 15px; /* Space between list items */
    display: flex; /* Align the numbers with the content */
}

li.numbers::before {
    font-family: "Avenir Black";
    content: counter(custom-counter); /* Use custom counter */
   
    
    font-size: 1.3vw; /* Adjust the size of the numbers */
    color: black; /* Color for the numbers */
    margin-right: 10px; /* Space between the number and the content */
}

li.numbers p{
    font-weight: normal;
    margin-left: 0.5vw;
    font-size: 1vw;
    font-family: "CEBT";
}
.ul.signature{
    font-size: 1.4vw;
}
.menu-items li {
    list-style-type: none;
}

.custom-card.sp{
    padding: 0px;
}
#what-we-offer,
#our-fridge,
#catering,
#results,
#contact-order {
display: flex;
justify-content: center;
align-items: center;
font-family: 'CEitBT';
    font-size: 30pt;
    text-align: center;
    margin-left: 20%;
    margin-top: 4vw;
    color: #333;
    font-weight: normal;
    text-align: center; /* Center the text */
  
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-weight: normal; /* Ensure the font weight is not bold */
}

.signature-text {
    margin-bottom: 0 !important;
}

.signature{
    margin-top: 0 !important;
}
.custom-card.custom-signature {
 
    flex-grow: 1;
    flex-shrink: 1;
    padding:3vw;
  
    box-sizing: border-box;
    background-color: white;
    display: center;
    flex-direction: left;
    
     text-align: center;
   
 
    overflow: clip;
    object-fit: cover;
 
    font-family: "CEBT";
}

p.first{
    font-size: 1vw
}
p.second {
    font-size: 1vw;
}
.custom-card.custom-signature.started{
    padding: 4vw;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
   
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
    justify-content: flex-start; /* Align content to the top */

    gap: 1vw; /* Add spacing between elements */
 
    overflow-clip-margin: content-box;
    overflow: clip;
    object-fit: cover;
    font-size: 1vw;
}

form {
height: 80%;
margin-top: 15%;
}
.custom-card.custom-signature.inTouch {
    padding: 4vw;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
  
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
    justify-content: flex-start; /* Align content to the top */

    gap: 1vw; /* Add spacing between elements */
 
    overflow-clip-margin: content-box;
    overflow: clip;
    object-fit: cover;
}

.custom-card.custom-signature.started p:first-of-type {
    margin-top: 0; /* Removes any margin that pushes it down */
    padding-top: 0; /* Removes padding, if any */
    font-size: 1vw;
  
}

.custom-card.custom-signature.inTouch p,
.custom-card.custom-signature.inTouch img {
    padding: 0; /* Ensure there is no padding on the individual elements */
    margin: 0;
}

.custom-card.custom-signature.inTouch img {
    clip-path: inset(0px 0 0 0);
    width: 100%; /* Scale the image to 50% of its container width */
    height: auto; /* Maintain aspect ratio */
    margin-top: 5%; /* Remove any additional margin */
    padding: 0; /* Ensure no padding */
    object-fit: contain; /* Ensure it doesn't get cropped */
    display: block; /* Remove any inline element spacing */
    margin-left: auto; /* Center image horizontally */
    margin-right: auto; /* Center image horizontally */
}


.custom-card.custom-signature.first {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
    overflow-clip-margin: content-box;
    overflow: clip;
    object-fit: cover;
}

.custom-card.custom-signature.second {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    overflow-clip-margin: content-box;
    overflow: clip;
    object-fit: cover;
}
.custom-card.custom-signature.klimat{
    padding: 2vw;
    font-size: 0.8vw;
}
signature-text.catering{
    font-size: 1vw;
    font-family: "CEBT";
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically centers the text and signature */
    align-items: center; 
}

/* Style for the paragraph (text) inside the custom signature card */
.signature-text.li {
    font-size: 10pt; /* Set text size to 25pt */
   
    font-weight: normal;
    margin: 0px;
    padding: 0px;
    max-width: 100%; /* Optional: Limit the width of the text */
 
    text-align: left !important;
    font-family: "CEBT";
}

.signature-text{
    font-size: 1.35vw;
    letter-spacing: -0.5px;
    margin-bottom: 1.5vw;
    font-family: "CEBT";
    hyphens: auto;

}

.signature-text.fridge{
    font-size: 1vw; /* 16pt equivalent */
    font-family: "CEBT";
    padding-top: 0vw;
    text-align: left;
    block-size: none;
    padding-bottom: 2vw;
    margin-bottom: 0%;
   
}



.signature-text.catering{
    font-size: 1vw; /* 16pt equivalent */
    font-family: "CEBT";
  
    text-align: left;
    block-size: none;
   

   
}
p.klimat{
    font-size: 0.8vw;
    hyphens: auto;
    padding-bottom: 2vw;
}
p.plats{
   font-size: 0.8vw;
   hyphens: auto;
}

.signature-text.chef.li {
    font-size: 33.325px; /* 25pt equivalent */
}

.signature-text.title{
    font-size: 1.35vw; /* 25pt equivalent */
    text-align: left;
    padding-bottom: 2vw;
    width: auto;
    height: auto;
    font-family: "CEBT";
}

.signature-text.chef {
    font-size: 1.35vw;
    text-align: left;
    block-size: none;
    margin: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 2vw;
    letter-spacing: auto;
    word-spacing: 1%;
    letter-spacing: -0.5px;
    text-align: auto;
    width: 100%;
    height: auto;
    font-family: "CEBT";
    hyphens: auto;
} 

.signature-text.chef-2 {
    font-size: 1vw; /* 16pt equivalent */
    text-align: left;
    padding-bottom: 0px;
    block-size: none;
    font-family: "CEBT";

} 
.signature-text.chef-2.li, .signature-text.li.li {
    font-size: 0.4vw; /* 16pt equivalent */
} 
/* Style for the signature image */
.signature img {
   
    margin-top: 2vw;
}

.custom-card.custom-signature.letter {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center child elements vertically */
    align-items: center; /* Center child elements horizontally */
    gap: 0; /* Remove gap between child elements */
    padding: auto !important; /* Retain the padding for this specific class */
    margin: auto; /* Ensure no additional margins */
    box-sizing: border-box; /* Ensure proper padding and border calculation */
    background-color: white;
    font-family: "CEBT";
}

/* Style for the name and title */
.signature-name {
    margin-top: 0.5vw;
    font-size: 0.9vw;
    font-weight: bold;
}



/* Header styling */
.header {
    background-color: #f8f8f8;
    color: #333;
    padding: 1vw 0;
    text-align: center;
    margin-top: 4vw;
    height: 4vw;
}

span {
    font-size:0.8vw;
}

.menu-week {
   font-weight: normal;
    
    font-style: italic;
    font-family: 'CEitBT';
    font-size: 3vw; /* Set font size to 50pt */
    text-align: center; /* Center the text */
    margin-top: 4vw;
    margin-bottom: 4vw;
    margin-left: 1%;
    letter-spacing: -0.1%;
    /*margin: 40px 20px 0 40px; /* Add margin to control spacing around the text */
    color: #333; /* Adjust color as needed */

    text-align: center;
}

/* Grid layout for food-items */
/* Container for all food items */
/* Container for all food items */
.food-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three items per row */
    gap: 15px; /* Space between each card */
    padding: 0px;
    max-width: 100%; /* Shrink the container to make it look zoomed out */
    margin: 0 ; /* Centering the container */
}

/* Individual food item container */
.food-item-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  
   
    background-color: #fff; /* Optional: add background for visual separation */
    border-radius: 0px; /* Optional: smooth borders */
}

/* Image styling to ensure uniform size */
.food-item-container img {
    width: 100%;
    height: 15.8vw;
  
  
    border-radius: 0px; /* Rounding the edges */
  
}


.food-item-container:hover {
    transform: translateY(-5px);
}

.food-item {
    width: 100%;
    height: 15.8vw; /* Ensure image scales with container */
    object-fit: cover;
}

.food-item-details {
    width: 100%;
  
    text-align: center;
    flex-shrink: 1;
}

.food-item-details h3 {
    text-align: left;
    font-size: 1.35vw;
    font-weight: normal;
    font-family: 'CEBT';
    padding: 1.3vw;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-bottom: 0px;
    margin-bottom: 1.3vw;
}

.line-above-button {
    border: none;
    border-top: 1px solid #333; /* Line color */
    width: 100%; /* Adjust width as needed */
    margin: 0.5vw; /* Center the line and add space */
}

.food-item-details p {
    text-align: left;
    font-size: 0.8vw;
    padding: 1.3vw;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0.25vw;
    margin-bottom: 1.3vw;
    font-family: 'CEBT';
}

.food-item-container:hover .food-item-details p {
    max-height: 10vw;
    overflow: visible;
}
.app-container {
    width: 50% !important; /* Full width to remove extra space */
    max-width: 100vw; /* Keep max-width for larger screens */
    margin: 0 auto; /* Center align */
    padding: 0; /* Remove any extra padding */
    box-sizing: border-box;}
/* Additional sections below the full menu */
.additional-sections {
  
    max-width: 100vw; /* Match the container width */
    width: 100%;             /* Ensures full width of the parent container */
    margin: 0 auto;          /* Center aligns the section horizontally */
    padding: 0;              /* Removes any internal padding */
    box-sizing: border-box; 
}

/* Custom rows to align sections */
.custom-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 1vw; /* Adjust gap between items */

    flex-wrap: nowrap; /* Prevent wrapping, unless necessary */
    margin-bottom: 2vw;
}
.custom-row.custom-row.three-items > * {
    flex: 1 1 calc(100% / 3 - 1vw); /* Ensure equal width distribution */
    box-sizing: border-box;
    padding: 0;
    min-width: 10vw; /* Set a minimum width to avoid shrinking too much */
    max-width: calc(100% / 3 - 1vw); /* Limit the width to 1/3 of the row */
    overflow: hidden; /* Ensure overflow is hidden */
}

.custom-row.custom-row.two-items > * {
    flex: 1 1 calc(100% / 2 - 1vw); /* Ensure equal width distribution */
    box-sizing: border-box;
   
    min-width: 10vw; /* Set a minimum width to avoid shrinking too much */
    max-width: calc(100% / 2 - 1vw); /* Limit the width to 1/3 of the row */
    overflow: hidden; /* Ensure overflow is hidden */
 
}


.custom-card, .custom-image {
    flex: 1; /* Ensures equal width distribution */
    box-sizing: border-box;
    padding: 0px; /* Adjust as needed */
    background-color: white; /* Optional background for visibility */
   

}


img{
    width: 100%;
    height: auto;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    object-fit: cover;
}

/* Custom card styling */
.custom-card {
    flex-grow: 1; /* Allows the card to grow to fill available space */
    flex-shrink: 1; /* Allows the card to shrink if necessary */
    flex-basis: auto; /* Automatically determine size based on content/parent */
    box-sizing: border-box;
    font-family: "CEBT";
    
   
    background-color: white;
    display: flex; /* For internal layout */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Vertically center content */
    align-items: center; /* Horizontally center content */
}



.custom-image {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    object-fit: cover;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}



.left-right-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1vw;
}

.results-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vw;
    padding: 2vw;
}

.custom-card.testimonials {
    display: grid;
    grid-template-columns: 1fr; /* Single column for the entire testimonials card */
    grid-gap: 0px; /* Space between rows */
    padding: 4vw;
    padding-bottom: 4vw;
  }
  
  .testimonial-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Default: Two columns */
    grid-gap: 1;
    margin-bottom: 0px;
  }
  .custom-card.custom-signature h3{
    font-size: 1.2vw;
   
    padding: 0px;
    text-align: center;
    margin-top: 0.5%;
    font-family: "Avenir Book";
  }

  .custom-card.custom-signature p.second{
    margin-top: 5%
  }
  
  .testimonial-card {
    background-color: white;
    padding: 5px;
    text-align: center; /* Default center alignment */
    box-shadow: none;
    margin-bottom: 0px;
  }

.testimonial-card p{
    font-weight: normal;
    font-size: 1.2vw;
    text-align: center;
    letter-spacing: -0.5px; 
    word-spacing: -1%;
    font-family: "CEBT";
    font-style: italic;
}
  .testimonial-card.left-align,  .testimonial-card.right-align {
    width:40%;
  }
  
  /* First testimonial row: single column, left aligned */
  .testimonial-row:first-child {
    grid-template-columns: 1fr;
  }
  
 
  
  /* Middle testimonials: standard two columns, centered */
  .testimonial-row:not(:first-child):not(:last-child) {
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  }
  
  /* Last testimonial row: align to the right */
  .testimonial-row:last-child {
   
    justify-content: space-between;
  }
  

  
  .testimonial-row:last-child .testimonial-card:first-child {
    opacity: 0;
    visibility: visible;
  }
  .testimonial-row:last-child .testimonial-card:last-child {
  
    width: 100%; /* Make it stretch to fill available space */
  }

 
.bars span{
    font-family: "Avenir Book";
    margin-right: 0px;
}

.rating-stars p{
    font-family: "Avenir Book";
    font-size: 1vw;
    justify-content: center;
    margin-bottom: 0%;
    width: 100%;
    text-align: right;
    margin-top: 1%;
}

.custom-card.ratings{

padding: 4vw 2vw 2vw 2vw;
}
p.bars{
    font-size: 1vw;
    margin-top: 8%;
    margin-bottom: 5%;
    text-align: center;
    font-family: "Avenir Book";
}
a:-webkit-any-link{
    color:black;
}


.ratings {
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;

    background-color: white;
    width: 100%; /* Ensures it takes the full width of the parent */
    margin: 0; /* Optional: Remove any margin */
    padding: 0; /* Optional: Remove any padding */
    box-sizing: border-box; /* Ensures padding is included in the width */
 
  
}

.rating-stars {
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1.5rem;
    flex-direction: row;
    display: flex;
    width: 100%;
  
}

.stars {
    font-size: 1.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
}

.bars {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5vw;
    margin-top: 4vw;
}

.bar {
    justify-content: space-between;
    height: 1.8vw;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0px;
    background-color: rgb(243, 240, 240);
}

.bar-fill {
    background-color: #000;
    height: 100%;
}

.bar span {
    flex-shrink: 0;
    margin-right: 1%
}

/* Social Media Section */
.social-media {
    justify-content: center;
    text-align: center;
}

.social-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: initial;
    padding: 1vw;
    text-align: center;
    max-width: 15vw;
    margin: 0 auto;
    font-family: "CEBT";
}

.social-icon {
    width: 2.5vw;
    height: 2.5vw;
    margin-bottom: 1vw;
}

.contact-details p {
    margin: 0.25vw 0;
    font-size: 1vw;
    color: #333;
    text-align: center;
    font-family: "Avenir Book";
}
.hat-image {
    margin-top: 0; /* Remove extra margin */
    width: 100%; /* Set width to fit container */
    height: auto; /* Maintain aspect ratio */
    max-width: 60%; /* Ensure it doesn’t exceed container */
    object-fit: cover; /* Crop image within container */
    transform: scale(1); /* Remove scaling */
}
.links a {
    display: block;
    margin: 0.25vw 0;
    color: #333;
    text-decoration: none;
    font-size: 0.7vwpx;
    text-align: center;
}

.links a:hover {
    text-decoration: underline;
}


.custom-card.background {
    background-color: transparent;
    box-shadow: none;
    
}

.hide-menu-wrapper {
    position: fixed;
    top: 1vw;
    right: 1vw;
    z-index: 1000;
}

.hide-menu-button {
    padding: 0.5vw 1vw;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 0.25vw;
    cursor: pointer;
}

.hide-menu-button:hover {
    background-color: #555;
}

.view-menu-wrapper {
    text-align: center;
    margin-top:2vw  ;
    margin-bottom: 2vw;
}

.view-menu-button-container,
.hide-menu-button-container {
    padding: 0px;
    display: flex;
   

    display: flex;
    flex-direction: column;
    align-items: center;

 
    width: 100%;
}

.custom-card.logos{
    padding: 4vw;
}
/* General styling for the grid and container */
.logos-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1vw;
    padding: 4vw;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    overflow: hidden;
    padding: 0px; /* Ensure the logos have some padding */
    max-height: 5vw;
}

.logo-container.logo-13{
    margin-bottom: 2.5vw;
}
.logo-container.logo-9{
    padding: 0vw;
    margin-bottom: 0vw;
}
.logo-container.logo-6{
    margin-bottom: 0vw;
}

.logo-container.logo-15{
    margin-bottom: 2vw;
}

.logo-container.logo-16{
    margin-bottom: 2vw;
}
.logo-container.logo-17{
    margin-bottom: 2vw;
}

.logo-container.logo-10{
    margin-bottom: 2vw;
}
.logo-container.logo-12{
    margin-bottom: 2vw;
}

.logo-container.logo-14{
    margin-bottom: 2vw;
}

/* Default logo image styling */
.logo-image {
    object-fit: contain;
}

/* Circular logos */
.logo-1 .logo-image{
    width: 5vw; /* Set a consistent width for circular logos */
    height: 5vw;
 
}

.logo-3 .logo-image,.logo-4 .logo-image, .logo-5 .logo-image  {
    width: 8vw; /* Set a consistent width for circular logos */
    height: 5vw;
 
}

.logo-0 .logo-image {
    width: 6vw; /* Set a consistent width for circular logos */
    height: 6vw;

}
.logo-2 .logo-image {
    width: 7.5vw; /* Set a consistent width for circular logos */
    height: 6vw;

}

/* Text-based logos */

.logo-6 .logo-image{
    width: 3vw; /* Set a consistent width for circular logos */
    height: 6vw;
    margin-bottom: 1vw;

}
.logo-7 .logo-image {
    max-width: 2vw; /* Limit the width */
    height: auto; /* Allow height to adjust proportionally */
    margin-bottom: 1vw;
 
}

.logo-8 .logo-image {
    max-width: 4.5vw; /* Limit the width */
    height: auto; /* Allow height to adjust proportionally */

}

.logo-9 .logo-image {
    width: 4vw; /* Set a consistent width for circular logos */
    height: 10vw;
}

.logo-10 .logo-image {
    width: 4.5vw !important;
    max-height: 3vw !important;
    margin-top: 1vw;
}

.logo-11 .logo-image {
    width: 6vw !important;
    height: auto !important;
}
.logo-12 .logo-image {
    width: 4vw !important;
    height: auto !important;
    margin-bottom: 0.5vw !important;
}

.logo-13 .logo-image {
    width: 4vw !important;
    height: auto !important;
}
.logo-14 .logo-image {
    width: 3vw !important;
    height: auto !important;
}

.logo-15 .logo-image {
    width: 4.5vw !important;
    height: auto !important;
}
.logo-16 .logo-image {
    width: 4vw !important;
    height: auto !important;
}
.logo-17 .logo-image {
    width: 4vw !important;
    height: auto !important;
}

.logo-18 .logo-image {
    width: 8vw !important;
    height: auto !important;
}
/* You can keep adding more specific rules like this depending on the index */




.view-menu-button,
.hide-menu-button {
    
    font-size: 1.3vw;
    z-index: 1;
    border-radius: 0.25vw;
    background-color: #333;
    color: white;
    cursor: pointer;
}
.call {
    display: flex
    ;
        justify-content: space-between;
        align-items: stretch;
        grid-gap: 1vw;
        gap: 1vw;
        flex-wrap: nowrap;
        margin-bottom: 2vw;
}


.view-menu-button:hover,
.hide-menu-button:hover {
    background-color: #555;
}

.custom-card.chef {
    padding: 3vw;
}

img.menu-logo {
    max-height:35vw;
    max-width: 22vw;
    margin:  auto;          /* Ensures it's centered within flex context */
    margin-left: 24%;
    display: block;
}
/* For screens narrower than 768px */
@media (max-width: 768px) {
    /* For food items grid, 2 per row */
    .food-items {
        grid-template-columns: repeat(2, 1fr); /* Two items per row */
    }

    .food-item-details p {
        display: none; /* Hide paragraph text */
    }

    .food-item-container {
        width:100%;
        text-align: center;
    }

    
 .food-item {
        width: 100%;
        height: 15.8vw; /* Ensure image scales with container */
    
    }
    .custom-row {
        flex: 1 1 100%;
        flex-wrap: wrap;
    }

    .custom-row.custom-row.three-items > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(100% );
        box-sizing: border-box;
        padding: 0;
        min-width: 10vw;
        max-width: calc(100%);
        overflow: hidden;
    }

    .custom-row.custom-row.two-items > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(100% );
        box-sizing: border-box;
        padding: 0;
        min-width: 10vw;
        max-width: calc(100% );
        overflow: hidden;
    }
    
    img.menu-logo {
        max-height: 35vw;
        max-width: 22vw;
        margin: auto;
        margin-left: 20%;
        display: block;
    }
    /* Custom rows with two items */
    .custom-row.two-items .custom-card {
        flex: 1 1 calc(100% / 2 - 0.5vw) !important;
        min-width: calc(100% / 2 - 0.5vw) !important;
        padding: 4vw !important;
    }
    .signature-text.li {
        padding: 3vw;
    }
    .custom-row.custom-row.two-items > * {
        flex-grow: 1;
        flex-shrink: 1;
      
        box-sizing: border-box;
        min-width: 10vw;
 
        overflow: hidden;
    }
    .custom-card.ratings {
        padding: 4vw 2vw 2vw 2vw !important;
    }

    /* Custom rows with three items */
    .custom-row.three-items .custom-card {
        flex: 1 1 calc(100% / 2 - 0.5vw) !important;
        min-width: calc(100% / 2 - 0.5vw) !important;
    }
  
    .custom-card.contact-info {
        display: none;
    }
    .call {
        display: flex
        ;
            justify-content: space-between;
            align-items: stretch;
            grid-gap: 1vw;
            gap: 1vw;
            flex-wrap: nowrap;
            margin-bottom: 2vw;
    }
    ul { 
        padding: 3vw};
}

/* For screens narrower than 480px */
@media (max-width: 480px) {
    .contact-form input[type="text"], .contact-form input[type="email"], .contact-form textarea {
        font-size: 3vw !important;
    }
    .custom-row.custom-row.three-items > *{
        padding: 2vw;
    }
    
    .contact-form label {
        font-size: 2vw !important;
    }
    .contact-form button {
        font-size: 3vw !important;
    }
    ul { 
        padding: 3vw;
        margin-left: 2vw
    };
    .custom-card.custom-signature.started.p{
        font-size: 2vw;
    }
    .custom-card.custom-signature.started{
        align-items: center;
        max-width: 100%; /* Limit the width to allow space for the second card */
    
    }
    .custom-card.custom-signature.inTouch {
        align-items: center;
        max-width: 100%; /* Limit the width to allow space for the second card */
      
    }

    .fabian {
        font-size: 3vw;
       
    }

.fabian p{
        font-family: "Avenir Book";
    }

    .custom-card.custom-signature.letter {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Align items to the top */
        align-items: center; /* Center-align items horizontally */
        gap: 1rem; /* Add spacing between the items */
        padding: 1rem; /* Optional: Adjust padding for better layout */
        box-sizing: border-box;
    }
    
    .signature-text {
        text-align: center; /* Center text for better visual alignment */
        margin: 0; /* Ensure no unexpected margins */
    }
    
    .signature-pic {
        max-height: 40vw;
        max-width: 40vw;
        margin-bottom: 0.5rem; /* Add spacing below the image */
    }
    
    .call {
        display: flex;
        flex-direction: column;
        align-items: center; /* Centers child elements horizontally */
        gap: 1rem; /* Adds spacing between child elements */
      }
      .view-menu-button {
        background: none;
        border: none; /* Removes the border */
        color: #333;
        font-size: 4vw;
        text-align: center;
        padding:  0px;
        position: relative;
        z-index: 1;
        cursor: pointer;
        font-family: Avenir Book;
      }
    
      .custom-card.custom-signature h3 {
        font-size: 3vw;
    }
    .menu-content {
        height: 10vw;
    }
    .food-item-details h3{
        font-size: 3vw;
    }
    .signature-text.fridge{
        font-size: 4vw;
    }
    .signature-text {
        font-size: 4vw;
    }
    .signature-text.chef {
        font-size: 4vw;
        margin-bottom: 4vw;
    }

    .signature-text.chef-2 {
        font-size: 3vw;
        margin-top: 4vw;
    }
    .signature-text.title {
        font-size:4vw; 
    }
    li {
        font-size: 2.5vw;
    }

    p.plats {
        font-size: 3vw;
    }
    p.klimat{
        font-size: 3vw ;
    }
    .custom-card{
        min-height: 60vw !important;
    }
    .custom-card.custom-signature.inTouch img {
        width: 30%; /* Reduce the width dynamically for small screens */
        margin-top: 3%; /* Adjust margin for better spacing */
    }
    .custom-row {
        flex: 1 1 100%;
        flex-wrap: wrap;
    }
    .signature-text.chef-2.li, .signature-text.li.li {
        font-size: 4vw;
    }

    li.numbers p {
        font-size: 3.5vw;
    }
    li.numbers::before {
        font-size: 4vw;
    }
   
    .app-container {
        width: 90% !important; /* Full width to remove extra space */}
        
    .custom-card.logos-grid {
        margin-top: 8vw;
    }

 
      .custom-card.chef{
        margin-bottom: 8vw;
      }

      .custom-card.signature4{
        margin-bottom: 8vw;
      }
       .custom-row {
       margin-top: 1.3vw;
       margin-bottom: 1.3vw;
      
       }

       p.first{
        font-size: 4vw;
       }
       .custom-card.custom-signature.started p:first-of-type{
        font-size: 4vw;
       }

    .menu-week {
        font-size: 5vw;
        margin-top: 6vw;
        margin-bottom: 4vw;
        letter-spacing: 0vw;
    }
    .menu-content {
        max-width: 100%;
        padding: 0 5%};
    .header {
       margin-top: 2vw;}

    /* For food items grid, 2 per row */
    .custom-card.contact-info {
        display: none;
    }
    .menu-logo {
        height: 2vw !important; /* Even smaller height for mobile screens */
        /*margin-left: 0.1% !important; /* Adjust margin for smaller screens */
        /*width: auto !important; /* Ensures it stays responsive */
}
.food-item-container img {
    width: 100%;
    height: 30vw;
    border-radius: 0px;
}
    
    .view-menu-wrapper{
        margin-top: 1.3vw;
        margin-bottom: 1.3vw;
    }
    h2#what-we-offer, h2#our-fridge, h2#catering, h2#results, h2#contact-order {
       
        font-size: 5vw;
        margin-bottom: 4vw;
    }

  

    #what-we-offer,
#our-fridge,
#catering,
#results,
#contact-order {
    margin-top: 3vw;
    margin-bottom: 3vw;
}
h2#catering{
    margin-top: 3vw;
}
.custom-card.background{
    max-height: 1vw !important;
}
 .social-media{
    max-height: 0.1vw !important;
 }
.custom-card, .custom-image {
    margin-top: 0px;
}
.custom-card.signature{
    margin-bottom: 1.3vw;
}
.custom-card.signature2{
    margin-bottom: 0px;
}
.custom-card.signature4{
    margin-bottom: 1.3vw;
}
.custom-card.logos{
    margin-top: 1.3vw;
    
}
.custom-card.chef {
    margin-bottom: 1.3vw;
}
.custom-row.three-items{
    margin-top: 0px;
   
}
.custom-card.ratings
    .view-menu-wrapper{
      
        margin-bottom: 0px;
    }
    .custom-row{
        margin-bottom: 0px;
        margin-top: 0px;
    }
  
    .view-menu-button-container {
        margin-bottom: 0px;
    }
  
    .custom-row {
        flex-wrap: wrap;
    }

    /* Custom rows with two items */
    .custom-row.two-items .custom-card {
        flex: 1 1 100% !important;
        min-width: 100% !important;
    }

    /* Custom rows with three items */
  
   
    .contact-bar {
        width: 100%;
        padding: 0.25vw;
    }
    .contact-bar p {
        margin-top: 0.25vw;
    }
    .contact-form-container.active{
        width: 100%;}

   

    .form-header p {
        font-size: small;
    }

/* Circular logos */
.logo-1 .logo-image{
    width: 20vw; /* Set a consistent width for circular logos */
    height: 20vw;
 
}

.logo-3 .logo-image,.logo-4 .logo-image, .logo-5 .logo-image  {
    width: 32vw; /* Set a consistent width for circular logos */
    height: 20vw;
 
}

.logo-0 .logo-image {
    width: 24vw; /* Set a consistent width for circular logos */
    height: 24vw;

}
.logo-2 .logo-image {
    width: 30vw; /* Set a consistent width for circular logos */
    height: 30vw;

}

/* Text-based logos */

.logo-6 .logo-image{
    width: 6vw; /* Set a consistent width for circular logos */
    height: 14vw;
    margin-bottom: 1vw;

}
.logo-7 .logo-image {
    max-width: 3vw; /* Limit the width */
    height: auto; /* Allow height to adjust proportionally */
    margin-bottom: 1vw;
 
}

.logo-8 .logo-image {
    max-width: 10vw; /* Limit the width */
    height: auto; /* Allow height to adjust proportionally */

}

.logo-9 .logo-image {
    width: 15vw; /* Set a consistent width for circular logos */
    height: 45vw;
}

.logo-10 .logo-image {
    width: 12vw !important;
    max-height: 9vw !important;
    margin-top: 1vw;
}

.logo-11 .logo-image {
    width: 20vw !important;
    height: auto !important;
}
.logo-12 .logo-image {
    width: 12vw !important;
    height: auto !important;
    margin-bottom: 0.5vw !important;
}

.logo-13 .logo-image {
    width: 7vw !important;
    height: auto !important;
}
.logo-14 .logo-image {
    width: 8vw !important;
    height: auto !important;
}

.logo-15 .logo-image {
    width: 11vw !important;
    height: auto !important;
}
.logo-16 .logo-image {
    width: 12vw !important;
    height: auto !important;
}
.logo-17 .logo-image {
    width: 10vw !important;
    height: auto !important;
}

.logo-18 .logo-image {
    width: 18vw !important;
    height: auto !important;
}
/* You can keep adding more specific rules like this depending on the index */

.custom-card.social-card {
    margin: 3vw;
    align-items: center;
    display:block;
    min-height: 10vw !important;
    max-height: 10vw !important;
}

}