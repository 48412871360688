.container {
  width: 100% !important;  /* Force 90% of #content-container */
  max-width: 2000px;      /* Cap at 1200px for larger screens */
  margin: 0 auto;         /* Center align within #content-container */
  padding: 0 5%;          /* Add padding for consistent spacing */
  box-sizing: border-box;
  width: 100% !important; /* Full width to remove extra space */
    max-width : 2000px !important; /* Keep max-width for larger screens */
    margin: 0 auto !important; /* Center align */
    padding: 0 !important; /* Remove any extra padding */
}

body {
  background-color: rgb(243, 240, 240); /* Light grayish background */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.header {
  background-color: rgb(243, 240, 240);
  color: #333;
  padding: 40px 0;
  text-align: center;
  margin-top: 80px;
}

.food-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Exactly three items per row */
  grid-gap: 2vw; /* Adjust gap between items */
  justify-items: center;
}

.food-item-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.view-menu-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top:2vw  ;
  margin-bottom: 2vw;
  
}





.view-menu-button {
  background: none;
  border: none; /* Removes the border */
  color: #333;
  font-size: 1.3vw;
  text-align: center;
  padding:  0px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-family: Avenir Book;
}

.view-menu-button:hover {
  color: #000;
}

.view-menu-button:after {

  font-size: 16px;
  color: #333;
  margin-left: 5px;
}


.section-card {
  background: white;
  padding: 40px;
  margin-top: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.section-card img {
  max-width: 100%;
  margin-top: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 40px;
}

.logo-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5vw;
  margin-top: 1.5vw;
}

ul {
  list-style-type: none;
  padding: 0;
  padding-inline-start:0px;
}
@media (max-width: 480px) {
  .header {
    margin-top: 53px;}
    .view-menu-button {
      background: none;
      border: none; /* Removes the border */
      color: #333;
      font-size: 5vw;
      text-align: center;
      padding:  0px;
      position: relative;
      z-index: 1;
      cursor: pointer;
      font-family: Avenir Book;
    }
}
